<template>
  <CRow class="justify-content-center">
    <CCol lg="4" md="5" col="12">
      <CCard>
        <CCardBody>
          <CForm>
            <TMessage content="Forgot password" size="h3" />
            <TMessage
              content="Reset your password"
              color="muted"
              class="mb-3"
            />
            <TInputEmail :value.sync="email" class="mb-3" />
            <CButton :disabled="sending" color="success" block @click="resetPW">
              <TMessage content="Reset password" alignment="center" />
            </CButton>
          </CForm>
        </CCardBody>
        <CCardFooter>
          <CButton block color="link" @click="$redirect.toLogin()">
            <TMessage content="Back to login" alignment="center" />
          </CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  data() {
    return {
      email: null,
      sending: false,
    };
  },
  methods: {
    resetPW() {
      this.sending = true;
      this.$store
        .dispatch("auth.send_mail.reset_password", this.email)
        .then(() => {
          this.$redirect.toLogin();
        })
        .finally(() => {
          this.sending = false;
        });
    },
  },
};
</script>
